import styled from "styled-components";

export const IconButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid ${({ theme }) => theme.customColors.primary};
    background-color: #fff;
    border-radius: 0.25rem;
`;
export const IconButtonWithSvg = styled.button`
    display: flex;
    padding: 12px 24px;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    white-space: initial;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.customColors.primary};
    background-color: ${({ theme }) => theme.customColors.primary};
    color: #fff;
    gap: 0.5rem;
    cursor: pointer;
    border-radius: 4px;
    & svg {
        width: 20px;
        fill: white;
        display: flex !important;
    }
    &:disabled {
        pointer-events: none;
        opacity: 0.65;
    }
`;

export const IconButtonNoStyle = styled.button<{ $border?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 4px;
    border: ${(props) => (props.$border ? `1px solid ${props.theme.customColors.primary}` : "none")};
    background-color: transparent;
    &:hover {
        opacity: 0.4;
    }
    & svg {
        width: 2rem;
        height: 2rem;
    }
`;
export const ThemedBtn = styled.button`
    border: 1px solid ${({ theme }) => theme.customColors.primary};
    background-color: ${({ theme }) => theme.customColors.primary};
    color: ${({ theme }) => theme.customColors.white};
    &:hover {
        background-color: ${({ theme }) => theme.customColors.secondary};
        color: ${({ theme }) => theme.customColors.white};
        opacity: 0.9;
    }
`;
export const IconButtonUnderlined = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 2.5rem;
    border: none;
    color: ${({ theme }) => theme.customColors.primary};
    background-color: #fff;
    margin-bottom: 1rem;
    &.active {
        border-bottom: 1px solid ${({ theme }) => theme.customColors.primary};
    }
    &:hover {
        opacity: 0.8;
    }
`;
