const breakpoints = {
    mobile: "480px",
    tablet: "768px",
    desktop: "1024px",
};
export const themeLight = {
    customColors: {
        primary: "#0066CC",
        secondary: "#1262b3",
        white: "#FFFFFF",
        neutralDark: "#17324d",
        neutralLight: "whitesmoke",
        grey: "#f5f5f5",
    },
    custombreakpoints: { ...breakpoints },
};

export const themeDark = {
    customColors: {
        primary: "#f90",
        secondary: "#ebb76a",
        white: "black",
        neutralDark: "#17324d",
        neutralLight: "whitesmoke",
        grey: "#f5f5f5",
    },
    custombreakpoints: { ...breakpoints },
};
