import { FlexCenter, StyledH3 } from "../style";
import { useContext, useEffect, useState } from "react";

import AccordionTable from "../components/AccordionRow/AccordionRow";
import { ICreateCertificateResponse } from "../model";
import Loading from "../components/Loading/Loading";
import { ThemeContext } from "../style/ThemeContext";
import { cryptData } from "../utils";
import { get } from "lodash";
import httpClient from "../api/httpClient";
import { toastError } from "../components/Alert/Alert";
import { useMsal } from "@azure/msal-react";
import useSWRMutation from "swr/mutation";
import { useTranslation } from "react-i18next";

const BlackList = () => {
    const { t } = useTranslation();
    const { isMutating, trigger: getBlackListData } = useSWRMutation(`BlackList/GetList`, httpClient.postRequest);
    const [rows, setRows] = useState<ICreateCertificateResponse[]>([]);
    const { instance } = useMsal();
    const activeAcc = instance.getActiveAccount();
    const { theme } = useContext(ThemeContext);

    const cf = get(activeAcc, "idTokenClaims.extension_fiscalNumber", "");
    const fetchData = async () => {
        try {
            if (cf) {
                const data: ICreateCertificateResponse[] = await getBlackListData({ data: cryptData({ fiscalCode: cf, email: "" }) });
                setRows(data);
            }
        } catch (err) {
            toastError(t("errors.generic"), theme);
        }
    };

    useEffect(() => {
        fetchData();
    }, [cf]);

    const headers = [
        t("tableHeader.name"),
        t("tableHeader.generated"),
        // t("tableHeader.creationDate"),
        t("tableHeader.commonName"),
        // t("tableHeader.organization"),
        // t("tableHeader.organizationUnit"),
        // t("tableHeader.structure"),
        t("tableHeader.expirationDate"),
    ];
    return (
        <div className="container mt-3">
            <FlexCenter>
                <StyledH3>{t("generic.blackListTable")}</StyledH3>
            </FlexCenter>
            <AccordionTable header={headers} setRows={setRows} rows={rows} isBlackList />

            {isMutating && <Loading />}
        </div>
    );
};

export default BlackList;
