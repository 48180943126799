import CryptoJS from "crypto-js";
import { format } from "date-fns";

export const validateEmail = (email: string): RegExpMatchArray | null => {
    return email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

export const cryptData = (data: any): string => {
    if (!!data) {
        const dataStringfied = JSON.stringify(data);
        const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPT_KEY ?? "");
        const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPT_IV ?? "");
        const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(dataStringfied), key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    }
    return "";
};
export const decryptData = (data: any): any => {
    if (!!data) {
        const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPT_KEY ?? "");
        const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPT_IV ?? "");
        const de = CryptoJS.AES.decrypt(data, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        let decryptedData = de.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
    }
    return "";
};
export const formatDate = (date?: string, removeHours?: boolean): string => {
    try {
        if (!date) return "";
        let dateTime = new Date(date);
        if (isNaN(dateTime.getTime())) {
            return "";
        }
        if (removeHours) {
            dateTime.setHours(dateTime.getHours() + 2);
        }
        return format(dateTime, "dd/MM/yyyy HH:mm");
    } catch (err) {
        console.log(err);
        return "";
    }
};

export const verifyCertificateName = (name: string) => {
    if (!name) return "";
    const allNumbers = name.length > 14 ? name.slice(0, 13) : "";
    if (isNaN(parseInt(allNumbers))) {
        return name;
    } else {
        return name.slice(14);
    }
};
