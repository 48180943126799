import { Card, Tooltip } from "react-bootstrap";
import styled, { css } from "styled-components";

const responsiveStyle = css`
    @media (max-width: ${({ theme }) => theme.custombreakpoints.mobile}) {
        font-size: 12px;
        max-width: 6rem;
        min-width: 6rem;
    }
    @media (min-width: ${({ theme }) => theme.custombreakpoints.mobile}) and (max-width: ${({ theme }) => theme.custombreakpoints.tablet}) {
        font-size: 14px;
        max-width: 8rem;
        min-width: 8rem;
    }
    @media (min-width: ${({ theme }) => theme.custombreakpoints.tablet}) and (max-width: ${({ theme }) => theme.custombreakpoints.desktop}) {
        font-size: 16px;
        min-width: 10rem;
        max-width: 10rem;
    }
`;

export const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    padding-left: 2.5rem;
    justify-content: space-between;
    border-bottom: 1px solid hsl(210deg, 4%, 78%);
`;

export const TableClmName = styled.p<{ $isName?: boolean }>`
    display: flex;
    max-width: ${({ $isName }) => ($isName ? "30rem" : "12rem")};
    min-width: ${({ $isName }) => ($isName ? "30rem" : "12rem")};
    padding: 16px 12px;
    text-align: left;
    vertical-align: bottom;
    font-size: 0.875rem;
    font-weight: bold;
    color: #777;
    text-transform: uppercase;
    margin: 0;
    ${responsiveStyle}
`;

export const CardBody = styled(Card.Body)`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.customColors.neutralLight};
    box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.2);
    gap: 1rem;
    @media (max-width: ${({ theme }) => theme.custombreakpoints.mobile}) {
        font-size: 12px;
    }
    @media (min-width: ${({ theme }) => theme.custombreakpoints.mobile}) and (max-width: ${({ theme }) => theme.custombreakpoints.tablet}) {
        font-size: 14px;
    }
    @media (min-width: ${({ theme }) => theme.custombreakpoints.tablet}) and (max-width: ${({ theme }) => theme.custombreakpoints.desktop}) {
        font-size: 16px;
    }
`;

export const DataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0 1rem 5%;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.customColors.grey};
    @media (max-width: ${({ theme }) => theme.custombreakpoints.desktop}) {
        gap: 1rem;
    }
`;

export const DivGap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2rem;
    & p {
        width: 50%;
    }
    @media (max-width: ${({ theme }) => theme.custombreakpoints.desktop}) {
        flex-direction: column;
        gap: 1rem;
    }
`;

export const CustomCardHeader = styled(Card.Header)`
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;
export const RowNames = styled.p<{ $isName?: boolean; $isBlackList?: boolean }>`
    padding: 0.5rem;
    text-align: left;
    vertical-align: bottom;
    max-width: ${({ $isName, $isBlackList }) => ($isName ? "30rem" : $isBlackList ? "7rem" : "12rem")};
    min-width: ${({ $isName, $isBlackList }) => ($isName ? "30rem" : $isBlackList ? "7rem" : "12rem")};
    font-weight: ${({ $isName }) => ($isName ? 600 : 400)};
    color: #777;
    cursor: pointer;
    ${responsiveStyle}
`;

export const BtnGroup = styled.div`
    display: flex;
    width: fit-content;
    padding: 0.5rem;
    gap: 1rem;
`;
export const StyledTooltip = styled(Tooltip)`
    font-size: 1rem;
    & .tooltip-inner {
        background-color: ${({ theme }) => theme.customColors.primary};
    }
`;
