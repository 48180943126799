import "./AccordionRow.css";

import { BtnGroup, CardBody, CustomCardHeader, DataWrapper, DivGap, RowNames, StyledTooltip, TableClmName, TableHeader } from "./AccordionRowStyled";
import { Card, OverlayTrigger, useAccordionButton } from "react-bootstrap";
import { Dispatch, SetStateAction, useCallback, useContext, useState } from "react";
import { Flex, PaginationWrapper } from "../../style";
import { ICreateCertificateResponse, IEmailTags } from "../../model";
import { IconButton, IconButtonNoStyle } from "../../style/buttons/buttons";
import { cryptData, formatDate, validateEmail } from "../../utils";
import styled, { useTheme } from "styled-components";

import Accordion from "react-bootstrap/Accordion";
import { Btn } from "../Buttons/Buttons";
import CustomModal from "../Modal/CustomModal";
import CustomSvg from "../Svg/CustomSvg";
import Loading from "../Loading/Loading";
import { MyPaginate } from "../../style/layout/paginate";
import { WithContext as ReactTags } from "react-tag-input";
import { ThemeContext } from "../../style/ThemeContext";
import { get } from "lodash";
import httpClient from "../../api/httpClient";
import toast from "react-hot-toast";
import { toastError } from "../Alert/Alert";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { useTranslation } from "react-i18next";

const AccordionHeader = ({
    eventKey,
    names,
    isBlackList,
    rows,
    certificate,
    setRows,
}: {
    eventKey: string;
    names: string[];
    certificate: ICreateCertificateResponse;
    isBlackList?: boolean;
    rows: ICreateCertificateResponse[];
    setRows: Dispatch<SetStateAction<ICreateCertificateResponse[]>>;
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const decoratedOnClick = useAccordionButton(eventKey, () => setOpen((state) => !state));
    const { trigger: changeEnable } = useSWRMutation(`CertificateRegistry/ChangeEnable`, httpClient.postRequest);
    const { trigger: blackListChangeEnable } = useSWRMutation(`BlackList/ChangeEnable`, httpClient.postRequest);
    const [loadingDelete, setLoadingDelete] = useState<number>(-1);
    const { instance } = useMsal();
    const activeAcc = instance.getActiveAccount();
    const cf = get(activeAcc, "idTokenClaims.extension_fiscalNumber", "");
    const navigate = useNavigate();
    const { theme } = useContext(ThemeContext);
    const changeEnableAsync = async (name: string, cId: number) => {
        try {
            if (cf) {
                const apiToCall = isBlackList ? (blackListChangeEnable as (params: { data: string }) => Promise<boolean>) : (changeEnable as (params: { data: string }) => Promise<boolean>);
                await apiToCall({ data: cryptData({ fiscalCode: cf, name }) });
                setRows(rows.filter((row) => row.id !== cId));
                toast.success(isBlackList ? t("success.restored") : t("success.certificateBlackList"));
            }
        } catch (err) {
            toastError(isBlackList ? t("errors.updateError") : t("errors.deleteError"), theme);
        } finally {
            setLoadingDelete(-1);
        }
    };

    return (
        <CustomCardHeader>
            <IconButtonNoStyle onClick={decoratedOnClick}>
                <CustomSvg className="icon icon-sm " iconName={open ? "it-collapse" : "it-expand"} />
            </IconButtonNoStyle>
            {names.map((name, index) => (
                <RowNames key={index} $isName={index === 0} onClick={decoratedOnClick}>
                    {name}
                </RowNames>
            ))}
            {isBlackList ? (
                <RowNames $isBlackList={isBlackList}>
                    {loadingDelete === certificate.id ? (
                        <Loading />
                    ) : (
                        <OverlayTrigger
                            key={`top-${certificate.id}`}
                            placement="top"
                            overlay={
                                <StyledTooltip id={`tooltip-restore-blacklist`}>
                                    <strong>{t("tooltip.restoreBlacklist")}</strong>
                                </StyledTooltip>
                            }
                        >
                            <IconButton
                                onClick={() => {
                                    changeEnableAsync(certificate.name, certificate.id);
                                    setLoadingDelete(certificate.id);
                                }}
                            >
                                <CustomSvg className="icon icon-sm " iconName={"it-restore"} />
                            </IconButton>
                        </OverlayTrigger>
                    )}
                </RowNames>
            ) : (
                <>
                    <BtnGroup>
                        <OverlayTrigger
                            key={`top-${certificate.id}`}
                            placement="top"
                            overlay={
                                <StyledTooltip id={`tooltip-restore`}>
                                    <strong>{t("tooltip.restore")}</strong>
                                </StyledTooltip>
                            }
                        >
                            <IconButton
                                onClick={() => {
                                    navigate(`/`, { state: { certificate: { ...certificate } } });
                                }}
                            >
                                <CustomSvg className="icon icon-sm " iconName={"it-restore"} />
                            </IconButton>
                        </OverlayTrigger>

                        {loadingDelete === certificate.id ? (
                            <Loading />
                        ) : (
                            <OverlayTrigger
                                key={`top-${certificate.id}-delete`}
                                placement="top"
                                overlay={
                                    <StyledTooltip id={`tooltip-delete`}>
                                        <strong>{t("tooltip.delete")}</strong>
                                    </StyledTooltip>
                                }
                            >
                                <IconButton
                                    onClick={() => {
                                        changeEnableAsync(certificate.name, certificate.id);
                                        setLoadingDelete(certificate.id);
                                    }}
                                >
                                    <CustomSvg className="icon icon-sm " iconName={"it-delete"} />
                                </IconButton>
                            </OverlayTrigger>
                        )}
                    </BtnGroup>
                    {/* <CustomModal
                        title={t("modal.title")}
                        description={t("modal.description")}
                        handleConfirm={() => {
                            changeEnableAsync(certificate.name, certificate.id);
                            setLoadingDelete(certificate.id);
                        }}
                        show={show !== undefined && show >= 0}
                        handleClose={() => setShow(undefined)}
                    /> */}
                </>
            )}
        </CustomCardHeader>
    );
};

interface IAccordionTable<T = any> {
    header: string[];
    rows: T[];
    setRows: Dispatch<SetStateAction<T[]>>;
    isBlackList?: boolean;
}
const RemoveBtn = (props: any) => {
    const { onRemove } = props;
    return <Btn onClick={onRemove} text="x" type="button" className="remove-btn" />;
};

const DataContent = styled.div`
    display: flex;
    gap: 1rem;
    width: 100%;
`;
const TagsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & label {
        font-weight: 600;
    }
    & > div {
        display: flex;
        width: 100%;
    }
`;
const Floppy = () => {
    const { customColors } = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={customColors.primary} className="bi bi-floppy" viewBox="0 0 16 16">
            <path d="M11 2H9v3h2z" />
            <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
        </svg>
    );
};

const AccordionBody = ({ item, rows, setRows }: { item: ICreateCertificateResponse; rows: ICreateCertificateResponse[]; setRows: Dispatch<SetStateAction<ICreateCertificateResponse[]>> }) => {
    const { t } = useTranslation();
    const initialTags = Array.isArray(item.recipients) && item.recipients.length > 0 ? item.recipients.map((data) => ({ id: data, text: data })) : [];
    const [tags, setTags] = useState<IEmailTags[]>(initialTags);
    const [show, setShow] = useState<number>(-1);
    const [loading, setLoading] = useState<boolean>(false);
    const [tagDisabled, setTagDisabled] = useState<boolean>(true);

    const { instance } = useMsal();
    const activeAcc = instance.getActiveAccount();
    const cf = get(activeAcc, "idTokenClaims.extension_fiscalNumber", "");
    const { theme } = useContext(ThemeContext);

    const handleDelete = (i: number) => {
        setTags(tags.filter((tag, index) => index !== i));
        setTagDisabled(false);
    };

    const handleAddition = (tag: IEmailTags) => {
        let isEmail = validateEmail(tag.text);
        if (!!isEmail) {
            setTagDisabled(false);

            setTags([...tags, tag]);
        } else {
            toastError(t("errors.emailFormat"), theme);
        }
    };
    const { trigger: updateEmails } = useSWRMutation(`CertificateRegistry/ModifyEmails`, httpClient.postRequest);

    const updateEmailsAsync = async () => {
        setLoading(true);
        toast.promise(updateEmails({ data: cryptData({ fiscalCode: cf, certificateName: item.name, emails: tags.map((data) => data.text) }) }), {
            loading: "...",
            success: () => {
                setLoading(false);
                setRows(rows.map((data) => (data.id === item.id ? { ...data, recipients: tags.map((data) => data.text) } : { ...data })));
                setShow(-1);
                setTagDisabled(true);
                return <b>{t("success.updateEmail")}</b>;
            },
            error: () => {
                setLoading(false);
                return <b>{t("errors.updateError")}</b>;
            },
        });
    };

    return (
        <Accordion.Collapse eventKey={`${item.id}`}>
            <CardBody>
                <Flex>
                    <CustomSvg className="icon" iconName={"it-file"} />
                    <p className="semib-f">{item.name}</p>
                </Flex>
                <DataWrapper>
                    <p className="b-f">{t("generic.infoCert")}</p>
                    <DivGap>
                        <p>
                            {t("tableHeader.creationDate")}: <span className="semib-f">{formatDate(item.creationDate, true)}</span>
                        </p>
                        <p>
                            {t("tableHeader.organization")}: <span className="semib-f">{item.organization}</span>
                        </p>
                    </DivGap>
                    <DivGap>
                        <p>
                            {t("tableHeader.organizationUnit")}:<span className="semib-f"> {item.organizationUnit}</span>
                        </p>
                        <p>
                            {t("tableHeader.structure")}:<span className="semib-f"> {item.structure}</span>
                        </p>
                    </DivGap>
                </DataWrapper>
                <DataWrapper>
                    <Flex>
                        <DataContent>
                            <TagsWrapper>
                                <label htmlFor="tags">{t("certificateForm.emailLabel")}</label>
                                <div>
                                    <ReactTags
                                        id="tags"
                                        name="emails"
                                        classNames={{
                                            tags: "tagsClass",
                                            tagInput: "tagInputClass",
                                            tagInputField: "tagInputFieldClass",
                                            selected: "selectedClass",
                                        }}
                                        autofocus={false}
                                        tags={tags}
                                        separators={["Enter", "Comma"]}
                                        handleDelete={handleDelete}
                                        handleAddition={handleAddition}
                                        inputFieldPosition="top"
                                        placeholder={"test@test.it"}
                                        allowDragDrop={false}
                                        allow
                                        removeComponent={RemoveBtn}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            setShow(item.id);
                                        }}
                                        disabled={tagDisabled}
                                        style={{ display: "flex", width: "2.5rem", alignSelf: "flex-start" }}
                                        className={`${tagDisabled ? "disabled" : ""}`}
                                    >
                                        <Floppy />
                                    </IconButton>
                                </div>
                            </TagsWrapper>
                        </DataContent>
                    </Flex>
                </DataWrapper>
                <CustomModal
                    disabled={loading}
                    title={t("modal.title")}
                    description={t("modal.description")}
                    handleConfirm={updateEmailsAsync}
                    show={show >= 0}
                    handleClose={() => {
                        if (!loading) setShow(-1);
                    }}
                />
            </CardBody>
        </Accordion.Collapse>
    );
};

const ITEMS = 10;

const AccordionTable = ({ rows, header, isBlackList, setRows }: IAccordionTable<ICreateCertificateResponse>) => {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + ITEMS;
    const currentItems = rows.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(rows.length / ITEMS);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * ITEMS) % rows.length;
        setItemOffset(newOffset);
    };
    return (
        <div className="container mt-3" style={{ overflowX: "scroll" }}>
            <TableHeader>
                {Array.isArray(header) &&
                    header.length > 0 &&
                    header.map((clmName, index) => (
                        <TableClmName key={index} $isName={index === 0}>
                            {clmName}
                        </TableClmName>
                    ))}
                <div style={{ width: "7rem" }}></div>
            </TableHeader>
            <Accordion style={{ display: "flex", flexDirection: "column", width: "fit-content" }} alwaysOpen>
                {Array.isArray(currentItems) &&
                    currentItems.length > 0 &&
                    currentItems.map((item) => (
                        <Card className="custom-card" key={item.id}>
                            <AccordionHeader
                                isBlackList={isBlackList}
                                certificate={item}
                                rows={rows}
                                setRows={setRows}
                                eventKey={`${item.id}`}
                                names={[item.name, item.creatorFullName, item.commonName, formatDate(item.expirationDate, true)]}
                            />
                            <AccordionBody item={item} rows={rows} setRows={setRows} />
                        </Card>
                    ))}
            </Accordion>
            {rows.length > 0 && (
                <PaginationWrapper>
                    <MyPaginate
                        breakLabel="..."
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                    />
                </PaginationWrapper>
            )}
        </div>
    );
};

export default AccordionTable;
