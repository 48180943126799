import { Link, useLocation } from "react-router-dom";

import Avatar from "../components/Avatar/Avatar";
import CustomSvg from "../components/Svg/CustomSvg";
import LanguageBtn from "../components/LanguageBtn/LanguageBtn";
import { route } from "../route";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface IHeaderProps {}

const StyledHeader = styled.div`
    padding: 0 18px !important;
    background-color: ${({ theme }) => theme.customColors.secondary};
`;
const RightWrapper = styled.div`
    background-color: ${({ theme }) => theme.customColors.primary};
`;
const MainContentWrapper = styled.div`
    background-color: ${({ theme }) => theme.customColors.primary};
`;
const Header = (props: IHeaderProps) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    return (
        <>
            <StyledHeader className="it-header-slim-wrapper">
                <div className="container ">
                    <div className="row ">
                        <div className="col-12 ">
                            <div className="it-header-slim-wrapper-content">
                                <Link className="d-lg-block navbar-brand" to={"/"}>
                                    {t("generic.title")}
                                </Link>
                                <RightWrapper className="it-header-slim-right-zone">
                                    <LanguageBtn />
                                    <Avatar />
                                </RightWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledHeader>
            <MainContentWrapper className="it-header-center-wrapper ">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="it-header-center-content-wrapper">
                                <div className="it-brand-wrapper d-flex">
                                    <Link to={"/"}>
                                        <div className="it-brand-text">
                                            <div className="it-brand-title">{t("generic.appTitle")}</div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainContentWrapper>
            <MainContentWrapper className="it-header-navbar-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="navbar navbar-expand-lg has-megamenu" aria-label="Navigazione principale">
                                <button
                                    className="custom-navbar-toggler"
                                    type="button"
                                    aria-controls="nav4"
                                    aria-expanded="false"
                                    aria-label="Mostra/Nascondi la navigazione"
                                    data-bs-toggle="navbarcollapsible"
                                    data-bs-target="#nav4"
                                >
                                    <CustomSvg iconName="it-burger" className="icon" white />
                                </button>
                                <div className="navbar-collapsable" id="nav4">
                                    <div className="overlay"></div>
                                    <div className="close-div">
                                        <button className="btn close-menu" type="button">
                                            <span className="visually-hidden">Nascondi la navigazione</span>
                                            <CustomSvg iconName="it-close-big" className="icon " />
                                        </button>
                                    </div>
                                    <div className="menu-wrapper">
                                        <ul className="navbar-nav">
                                            <li className="nav-item active">
                                                <Link className={pathname === route.home ? "nav-link active" : "nav-link"} to={route.home}>
                                                    <span>{t("routes.home")}</span>
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link className={pathname === "/" + route.certificateTable ? "nav-link active" : "nav-link"} to={route.certificateTable}>
                                                    <span>{t("routes.certificates")}</span>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={pathname === "/" + route.blackList ? "nav-link active" : "nav-link"} to={route.blackList}>
                                                    <span>{t("routes.blackList")}</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </MainContentWrapper>
        </>
    );
};

export default Header;
