import { themeDark, themeLight } from "../../style/theme";

import { CustomThemes } from "../../style/ThemeContext";
import toast from "react-hot-toast";

export const toastError = (msg: string, theme: CustomThemes) => {
    const actualTheme = !!theme && theme === "light" ? themeLight : themeDark;
    toast.error(msg, {
        style: {
            border: `1px solid ${actualTheme.customColors.primary}`,
            padding: "16px",
            color: actualTheme.customColors.primary,
        },
        icon: "!",
        iconTheme: {
            primary: actualTheme.customColors.primary,
            secondary: actualTheme.customColors.secondary,
        },
    });
};
