import { Container, FlexCenter, RowInputsWrapper, StyledH3 } from "../style";
import { useContext, useEffect, useState } from "react";

import AccordionTable from "../components/AccordionRow/AccordionRow";
import { ICreateCertificateResponse } from "../model";
import Loading from "../components/Loading/Loading";
import { ThemeContext } from "../style/ThemeContext";
import { cryptData } from "../utils";
import { get } from "lodash";
import httpClient from "../api/httpClient";
import styled from "styled-components";
import { toastError } from "../components/Alert/Alert";
import { useMsal } from "@azure/msal-react";
import useSWRMutation from "swr/mutation";
import { useTranslation } from "react-i18next";

enum SELECT_VALUE {
    all = "DEFAULT",
    expired = "expired",
    notExpired = "notExpired",
}

const Certificate = () => {
    const { t } = useTranslation();
    const { isMutating, trigger: getData } = useSWRMutation(`CertificateRegistry/GetList`, httpClient.postRequest);
    const [rows, setRows] = useState<ICreateCertificateResponse[]>([]);
    const [rowsToShow, setRowsToShow] = useState<ICreateCertificateResponse[]>([]);
    const { instance } = useMsal();
    const activeAcc = instance.getActiveAccount();
    const { theme } = useContext(ThemeContext);

    const cf = get(activeAcc, "idTokenClaims.extension_fiscalNumber", "");
    const fetchData = async () => {
        try {
            if (cf) {
                const data: ICreateCertificateResponse[] = await getData({ data: cryptData({ fiscalCode: cf, email: "" }) });
                setRows(data);
                setRowsToShow(data);
            }
        } catch (err) {
            toastError(t("errors.generic"), theme);
        }
    };

    useEffect(() => {
        fetchData();
    }, [cf]);

    const headers = [
        t("tableHeader.name"),
        t("tableHeader.generated"),
        // t("tableHeader.creationDate"),
        t("tableHeader.commonName"),
        // t("tableHeader.organization"),
        // t("tableHeader.organizationUnit"),
        // t("tableHeader.structure"),
        t("tableHeader.expirationDate"),
    ];

    return (
        <div className="container mt-3">
            <Container>
                <FlexCenter>
                    <StyledH3>{t("generic.certificateTable")}</StyledH3>
                </FlexCenter>
                <RowInputsWrapper>
                    <div className="select-wrapper">
                        <label htmlFor="certificate-filter">{t("generic.certificateFilter")}</label>
                        <select
                            id="certificate-filter"
                            name="certificate-filter"
                            onChange={(event) => {
                                const value = event.target.value;
                                switch (value) {
                                    case SELECT_VALUE.expired:
                                        setRowsToShow(rows.filter((row) => row.isExpired));
                                        break;
                                    case SELECT_VALUE.notExpired:
                                        setRowsToShow(rows.filter((row) => !row.isExpired));
                                        break;
                                    default:
                                        setRowsToShow(rows);
                                        break;
                                }
                            }}
                        >
                            <option value={SELECT_VALUE.all}>{t("generic.all")}</option>
                            <option value={SELECT_VALUE.expired}>{t("generic.expiredCert")}</option>
                            <option value={SELECT_VALUE.notExpired}>{t("generic.notExpired")}</option>
                        </select>
                    </div>
                </RowInputsWrapper>
                {/* <CertificateTable header={headers} rows={rowsToShow} setRows={setRowsToShow} /> */}
                <AccordionTable header={headers} setRows={setRowsToShow} rows={rowsToShow} />

                {isMutating && <Loading />}
            </Container>
        </div>
    );
};

export default Certificate;
