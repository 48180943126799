import { ELanguages, changeLanguage, languages } from "../../localization";
import { StyledDropdown, StyledDropdownItem, StyledDropdownToggle } from "../../style/dropdown/dropdown";

import CustomSvg from "../Svg/CustomSvg";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

const LanguageBtn = () => {
    const { i18n } = useTranslation();

    const isIt = i18n.language === ELanguages.it;

    return (
        <StyledDropdown className="p-2 nav-link dropdown-toggle">
            <StyledDropdownToggle variant="success" id="dropdown-basic">
                {isIt ? "IT" : "DE"}
                <CustomSvg className="icon icon-white" iconName="it-expand" />
            </StyledDropdownToggle>
            <Dropdown.Menu>
                <StyledDropdownItem onClick={() => changeLanguage(isIt ? ELanguages.de : ELanguages.it)}>{isIt ? languages[ELanguages.de] : languages[ELanguages.it]}</StyledDropdownItem>
            </Dropdown.Menu>
        </StyledDropdown>
    );
};

export default LanguageBtn;
