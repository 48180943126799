import { StyledDropdown, StyledDropdownItem, StyledDropdownToggle } from "../../style/dropdown/dropdown";

import Dropdown from "react-bootstrap/Dropdown";
import { ThemeContext } from "../../style/ThemeContext";
import { get } from "lodash";
import { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

const Avatar = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    let activeAccount = instance.getActiveAccount();
    const cf = get(activeAccount, "idTokenClaims.extension_fiscalNumber", "");
    const { theme, setTheme } = useContext(ThemeContext);
    if (!activeAccount && !cf) return null;
    const lastName = get(activeAccount, "idTokenClaims.family_name", "");
    const firstName = get(activeAccount, "idTokenClaims.given_name", "");
    return (
        <StyledDropdown className="p-2 nav-link dropdown-toggle">
            <StyledDropdownToggle variant="success" id="dropdown-basic">
                <div className="d-flex align-items-center justify-content-around flex-wrap flex-sm-nowrap">
                    <div className="avatar ">
                        <p aria-hidden="true">{`${firstName[0] ?? ""}${!!lastName ? lastName[0] : "User"}`}</p>
                        <span className="visually-hidden">
                            {firstName ?? ""} {lastName ?? "U"}
                        </span>
                    </div>
                </div>
            </StyledDropdownToggle>
            <Dropdown.Menu>
                <StyledDropdownItem
                    onClick={() => {
                        instance.logoutRedirect();
                    }}
                >
                    {t("generic.logout")}
                </StyledDropdownItem>
                <StyledDropdownItem onClick={() => setTheme(theme === "light" ? "dark" : "light")}>{t("generic.changeTheme")}</StyledDropdownItem>
            </Dropdown.Menu>
        </StyledDropdown>
    );
};

export default Avatar;
