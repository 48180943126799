import ReactPaginate from "react-paginate";
import styled from "styled-components";

export const MyPaginate = styled(ReactPaginate).attrs({
    // You can redefine classes here, if you want.
    activeClassName: "active", // default to "selected"
})`
    margin-bottom: 2rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    color: ${({ theme }) => theme.customColors.primary};
    gap: 0.2rem;
    flex-wrap: wrap;
    li a {
        border-radius: 4px;
        padding: 0.1rem 1rem;
        border: ${({ theme }) => theme.customColors.primary} 1px solid;
        cursor: pointer;
        text-decoration: none;
        color: ${({ theme }) => theme.customColors.primary};
    }
    li.previous a,
    li.next a,
    li.break a {
        border-color: transparent;
    }
    li.active a {
        background-color: ${({ theme }) => theme.customColors.primary};
        border: ${({ theme }) => theme.customColors.primary} 1px solid;
        border-color: transparent;
        color: white;
        border-radius: 4px;
    }
    li.disabled a {
        color: grey;
    }
    li.disable,
    li.disabled a {
        cursor: default;
        color: ${({ theme }) => theme.customColors.primary};
    }
`;

export const PaginationWrapper = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
`;
