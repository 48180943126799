import * as yup from "yup";

import { Btn, IconBtnWithText } from "../Buttons/Buttons";
import { Button, Spinner } from "react-bootstrap";
import { FlexCenter, FormContainer } from "../../style";
import { ICertificateForm, ICreateCertificateResponse, IEmailTags, IGetCertificateForm } from "../../model";
import { cryptData, decryptData, validateEmail, verifyCertificateName } from "../../utils";
import { useContext, useEffect, useState } from "react";

import Countdown from "react-countdown";
import CustomSelect from "../Select/CustomSelect";
import CustomSvg from "../Svg/CustomSvg";
import { Formik } from "formik";
import { WithContext as ReactTags } from "react-tag-input";
import ScrollToFieldError from "../ScrollToField/ScrollToField";
import { TextInput } from "../Input/Input";
import { ThemeContext } from "../../style/ThemeContext";
import axios from "axios";
import { get } from "lodash";
import httpClient from "../../api/httpClient";
import styled from "styled-components";
import { t } from "i18next";
import { toastError } from "../Alert/Alert";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import useSWRMutation from "swr/mutation";
import { useTranslation } from "react-i18next";

const certificateSchema = yup.object({
    organization: yup.string().required(t("errors.fieldRequired")),
    commonName: yup.string().required(t("errors.fieldRequired")),
    organizationUnit: yup.string().required(t("errors.fieldRequired")),
    certificateName: yup.string().max(150, t("errors.validationMaxLength")),
    structure: yup.string().max(150, t("errors.validationMaxLength")),
});

interface ICertificateFormProps {}

const certificateInitialValues: ICertificateForm = {
    certificateName: "",
    commonName: "",
    organization: "",
    organizationUnit: "",
    structure: "",
};

const StyledLabel = styled.label`
    transform: translateY(-45%);
    font-weight: 600;
    font-size: 0.875rem;
    color: #1a1a1a;
    padding: 0 0.5rem;
`;

const CertificateForm = (props: ICertificateFormProps) => {
    const { t } = useTranslation();

    const { trigger: fetchCertificateData } = useSWRMutation("CertificateManagement/GetCreationForm", httpClient.postRequest);
    const { trigger: createCertificate } = useSWRMutation("CertificateManagement/CreateCertificate", httpClient.postRequest);

    const [certificateData, setCertificateData] = useState<IGetCertificateForm>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
    const [tags, setTags] = useState<IEmailTags[]>([]);
    const [isDownloadAvailable, setIsDownloadAvailable] = useState<string>("");
    const { instance } = useMsal();
    const [error, setError] = useState<boolean>(false);
    const activeAcc = instance.getActiveAccount();
    const cf = get(activeAcc, "idTokenClaims.extension_fiscalNumber", "");

    const location = useLocation();
    let certificate = get(location, "state.certificate", undefined) as ICreateCertificateResponse | undefined;
    const { theme } = useContext(ThemeContext);

    const fetchData = async () => {
        setLoading(true);
        try {
            const data: IGetCertificateForm = await fetchCertificateData({ data: cryptData({ fiscalCode: cf, email: "" }) });
            setCertificateData(data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        if (!!certificate) {
            setTags(certificate.recipients.map((data) => ({ id: data, text: data })));
        }
    }, []);

    const handleDelete = (i: number) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag: IEmailTags) => {
        let isEmail = validateEmail(tag.text);
        if (!!isEmail) {
            setTags([...tags, tag]);
        } else {
            toastError(t("errors.emailFormat"), theme);
        }
    };

    const createCertificateOnSubmit = async (values: ICertificateForm) => {
        setLoadingCreate(true);
        try {
            const data: ICreateCertificateResponse = await createCertificate({
                data: cryptData({
                    commonName: values.commonName,
                    subjectAlternativeName: values.structure,
                    organization: values.organization,
                    organizationUnit: values.organizationUnit,
                    certificateName: values.certificateName,
                    email: "",
                    notifyEmail: tags.map((email) => email.text).join(","),
                    fiscalCode: cf,
                }),
            });

            const nameUncrypted: { name: string } = decryptData(data);
            setIsDownloadAvailable(nameUncrypted.name);
        } catch (err) {
            toastError(t("errors.createCertificate"), theme);
        } finally {
            setLoadingCreate(false);
        }
    };

    return (
        <>
            {loading && (
                <FlexCenter>
                    <Spinner animation="border" />
                </FlexCenter>
            )}
            {error && <p>{t("errors.contact")}</p>}
            {certificateData && (
                <Formik
                    initialValues={
                        !!certificate
                            ? {
                                  commonName: certificate.commonName,
                                  structure: certificate.structure,
                                  organization: certificate.organization,
                                  organizationUnit: certificate.organizationUnit,
                                  certificateName: verifyCertificateName(certificate.name),
                                  notifyEmail: [],
                              }
                            : certificateInitialValues
                    }
                    enableReinitialize={true}
                    validationSchema={certificateSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values) => {
                        createCertificateOnSubmit(values);
                    }}
                >
                    {({ handleSubmit, setFieldValue, errors }) => (
                        <FormContainer onSubmit={handleSubmit}>
                            <ScrollToFieldError />
                            <CustomSelect
                                name="commonName"
                                required
                                label={t("certificateForm.commonNameLabel")}
                                description={t("certificateForm.commonNameDescription")}
                                parentOnChange={(value) => {
                                    setFieldValue("certificateName", value.replaceAll(" ", ""));
                                }}
                                options={certificateData.csr.commonNameValues.map((cnv) => ({ value: cnv, label: cnv, id: crypto.randomUUID() }))}
                            />
                            <CustomSelect
                                name="organization"
                                required
                                label={t("certificateForm.organizationLabel")}
                                options={certificateData.csr.organizationValues.map((ov) => ({ value: ov, label: ov, id: crypto.randomUUID() }))}
                            />
                            <CustomSelect
                                name="organizationUnit"
                                required
                                label={t("certificateForm.organizationUnitLabel")}
                                options={certificateData.csr.orgUnitValues.map((ouv) => ({ value: ouv, label: ouv, id: crypto.randomUUID() }))}
                            />
                            <TextInput name="certificateName" label={t("certificateForm.certificateNameLabel")} description={t("certificateForm.saveDescription")} />
                            <TextInput name="structure" label={t("certificateForm.structureName")} />
                            <div>
                                <StyledLabel htmlFor="tags">{t("certificateForm.emailLabel")}</StyledLabel>
                                <ReactTags
                                    id="tags"
                                    name="emails"
                                    classNames={{
                                        tags: "tagsClass",
                                        tagInput: "tagInputClass",
                                        tagInputField: "tagInputFieldClass",
                                        selected: "selectedClass",
                                    }}
                                    autofocus={false}
                                    tags={tags}
                                    separators={["Enter", "Comma"]}
                                    handleDelete={handleDelete}
                                    handleAddition={handleAddition}
                                    inputFieldPosition="top"
                                    placeholder={t("certificateForm.emailLabel")}
                                    allowDragDrop={false}
                                    allow
                                    removeComponent={RemoveBtn}
                                />
                            </div>
                            {loadingCreate ? (
                                <Button disabled>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    <span>{t("btns.loadingCreate")}</span>
                                </Button>
                            ) : (
                                <Btn text={t("btns.create")} type="submit" />
                            )}
                        </FormContainer>
                    )}
                </Formik>
            )}
            {isDownloadAvailable && <CountDownDownload certificateName={isDownloadAvailable} cf={cf} date={Date.now() + 1800000} />}
        </>
    );
};
const CountDownDownload = ({ certificateName, cf, date }: { certificateName: string; cf: string; date: number }) => {
    const [remainingTime, setRemainingTime] = useState(1);
    const [loading, setLoading] = useState<boolean>(false);
    const { theme } = useContext(ThemeContext);

    const { trigger: downloadCertificateMutation } = useSWRMutation("CertificateManagement/DownloadPfx", httpClient.postRequestForBlob);

    const downloadCertificate = async () => {
        setLoading(true);
        try {
            let response = await downloadCertificateMutation({
                data: cryptData({
                    name: certificateName,
                    fiscalCode: cf,
                }),
            });
            const url = window.URL.createObjectURL(response);
            const hiddenLink = document.createElement("a");
            hiddenLink.href = url;
            hiddenLink.setAttribute("download", `${certificateName}.pfx`);
            hiddenLink.setAttribute("target", "_blank");
            document.body.appendChild(hiddenLink);
            hiddenLink.click();
            document.body.removeChild(hiddenLink);
        } catch (err) {
            if (axios.isAxiosError(err) && !!err && !!err.response) {
                let errText = await err.response.data.text();
                toastError(errText, theme);
            } else {
                toastError(t("errors.downloadCertificateError"), theme);
            }
        } finally {
            setLoading(false);
        }
    };

    const renderer = ({ completed, minutes, seconds }: any) => {
        if (completed) {
            return <></>;
        } else {
            return (
                <p>
                    {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </p>
            );
        }
    };

    return (
        <div>
            <p>{t("certificateForm.countDownDescr")}</p>
            {remainingTime <= 0 ? (
                <p> 00:00</p>
            ) : (
                <Countdown
                    date={date}
                    renderer={renderer}
                    onComplete={() => {
                        setRemainingTime(0);
                    }}
                />
            )}
            {loading ? (
                <Button variant="primary" disabled>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span>{t("btns.download")}</span>
                </Button>
            ) : (
                <IconBtnWithText text={t("btns.download")} type="button" onClick={downloadCertificate} disabled={remainingTime <= 0}>
                    <CustomSvg iconName="it-download" white />
                </IconBtnWithText>
            )}
        </div>
    );
};
const RemoveBtn = (props: any) => {
    const { onRemove } = props;
    return <Btn onClick={onRemove} text="x" type="button" className="remove-btn" />;
};

export default CertificateForm;
