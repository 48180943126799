import { RouteObject, createBrowserRouter } from "react-router-dom";

import BlackList from "../pages/BlackList";
import Certificate from "../pages/Certificate";
import ErrorBoundary from "./ErrorBoundary";
import Home from "../pages/Home";
import Layout from "../layout/Layout";
import NotFound from "./NotFound";

export const route = {
    notFound: "*",
    home: "/",
    certificateTable: "certificates",
    blackList: "black-list",
};

const createRoute = (path: string, element: React.ReactNode) => ({
    path,
    element,
    errorElement: <ErrorBoundary />,
});
const defaultRoute: RouteObject = {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
};

const USER_PATHS = [
    {
        ...defaultRoute,
        children: [createRoute(route.home, <Home />), createRoute(route.certificateTable, <Certificate />), createRoute(route.blackList, <BlackList />), createRoute(route.notFound, <NotFound />)],
    },
];

export const adminPath = createBrowserRouter(USER_PATHS);
