import { CustomThemes, ThemeContext } from "./ThemeContext";
import { themeDark, themeLight } from "./theme";
import { useEffect, useState } from "react";

import { ThemeProvider } from "styled-components";

const CustomTheme = ({ children }: { children: any }) => {
    const [theme, setTheme] = useState<CustomThemes>("light");

    useEffect(() => {
        let actualTheme = theme === "light" ? themeLight : themeDark;
        Object.keys(actualTheme.customColors).forEach((key) => document.documentElement.style.setProperty(`--${key}`, actualTheme.customColors[key]));
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            <ThemeProvider theme={theme === "light" ? themeLight : themeDark}>{children}</ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default CustomTheme;
