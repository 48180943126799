import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

export const StyledDropdown = styled(Dropdown)`
    display: flex;
    padding: 0;
`;
export const StyledDropdownToggle = styled(Dropdown.Toggle)`
    display: flex;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.customColors.primary} !important;
    font-size: 1rem;
    padding: 0.5rem;
    font-weight: normal;
    align-items: center;
    gap: 0.5rem;
`;
export const StyledDropdownItem = styled(Dropdown.Item)`
    text-decoration: none;
    color: ${({ theme }) => theme.customColors.primary} !important;
`;
