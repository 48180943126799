import { Btn, BtnOutLine } from "../Buttons/Buttons";

import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

interface IModalProps {
    show: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    title: string;
    description: string;
    disabled?: boolean;
}
const CustomModal = (props: IModalProps) => {
    const { show, handleClose, title, description, handleConfirm, disabled } = props;
    const { t } = useTranslation();
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{description}</Modal.Body>
            <Modal.Footer>
                <BtnOutLine text={t("btns.cancel")} onClick={handleClose} disabled={disabled} />
                <Btn text={t("btns.confirm")} onClick={handleConfirm} disabled={disabled} />
            </Modal.Footer>
        </Modal>
    );
};

export default CustomModal;
